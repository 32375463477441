import React, { Suspense } from 'react'
import Page from '../components/Page'
import Layout from '../components/Layout'
import { graphql } from 'gatsby'
import StoryblokService from '../utils/storyblok-service'
import { PageContext } from 'src/utils/context'
import LoadingState from 'src/components/LoadingState'

export default class Index extends React.Component {
  state = {
    story: {
      content: this.props.data.story
        ? JSON.parse(this.props.data.story.content)
        : {},
    },
    cta_referrer: null,
    isLoading: true,
  }

  async getInitialStory() {
    // Only fetch from Storyblok in preview mode or development
    if (this.props.location.search.includes('_storyblok') || process.env.NODE_ENV === 'development') {
      StoryblokService.setQuery(this.props.location.search)
      let {
        data: { story },
      } = await StoryblokService.get(
        `cdn/stories/${this.props.data.story.full_slug}`,
      )
      return story
    }
    return null
  }

  async componentDidMount() {
    this.setState({ isLoading: true });
    
    let story = await this.getInitialStory()
    if (story?.content) {
      this.setState({ 
        story,
        isLoading: false 
      })
    } else {
      this.setState({ isLoading: false });
    }
    
    // Initialize Storyblok editor
    if (process.env.NODE_ENV === 'development' || this.props.location.search.includes('_storyblok')) {
      setTimeout(() => StoryblokService.initEditor(this), 200)
    }
  }

  setCtaReferrer = value => {
    this.setState({ cta_referrer: value })
  }

  render() {

    return (
      <Suspense fallback={<LoadingState />}>
        <PageContext.Provider
          value={{
            hasHero:
              this.state.story.content.body &&
              ['hero', 'hero-custom'].includes(
                this.state.story.content.body[0]?.component,
              )
                ? true
                : false,
            currentPage: this.state.story.uuid,
            breadcrumb: this.props.pageContext.breadcrumb,
            location: this.props.location,
            setCtaReferrer: this.setCtaReferrer,
            ctaReferrer: this.state.cta_referrer,
          }}
        >
          {this.state.story.content.component === 'OrphanPage' ? (
            <Layout location={this.props.location} page={'OrphanPage'}>
              <Page blok={this.state.story.content} page={'OrphanPage'} />
            </Layout>
          ) : (
            <Layout location={this.props.location} page={'page'}>
              <div className="">
                <Page blok={this.state.story.content} page={'page'} />
              </div>
            </Layout>
          )}
        </PageContext.Provider>
      </Suspense>
    )
  }
}

export const query = graphql`
  {
    story: storyblokEntry(full_slug: { eq: "home" }) {
      name
      content
      full_slug
      uuid
    }
  }
`
